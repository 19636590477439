import * as React from 'react';
import { map as _map } from 'lodash';
import Box from '@lce/slice_v2/Layout/Box';
import Flex from '@lce/slice_v2/Layout/Flex';

import { IActiveBannerProps } from '../../../api/HomePage/useActiveBanner';
import ImageBanner from '../../Patterns/Banners/ImageBanner';
import TextBanner from '../../Patterns/Banners/TextBanner';
import { sanitizedList } from './sanitizedList';

export interface IBannerLoaderProps {
  bannerData: IActiveBannerProps[];
  isFullWidth: boolean;
}

const BannerLoader: React.FC<IBannerLoaderProps> = ({ bannerData, isFullWidth }) => {
  const listData = sanitizedList(bannerData);
  const hasBanners = listData.length > 0;

  if (!hasBanners) return null;

  return (
    <Flex data-testId="banner-loader" sx={{ flexDirection: 'column', gap: 4, maxWidth: isFullWidth ? '100%' : '1280px', width: '100%' }}>
      {// eslint-disable-next-line consistent-return
        _map(listData, (banner: IActiveBannerProps) => {
          if (banner.imageBanner) {
            return (
              <Box as="section" data-testid="image-banners">
                <ImageBanner {...banner.imageBanner} />
              </Box>
            );
          }
          if (banner.textBanner) {
            return (
              <Flex data-testid="text-banners" sx={{ width: '100%', justifyContent: 'center', backgroundColor: 'primary' }}>
                <TextBanner {...banner.textBanner} direction={banner.direction} locale={banner.locale} />
              </Flex>
            );
          }
        })}
    </Flex>
  );
};

export default BannerLoader;
