import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';

import ComponentListRenderer from '@lce/intl-ui/src/Modules/ComponentListRenderer';
import Script from '@lce/intl-ui/src/Elements/Script';
import Superscript from '@lce/intl-ui/src/Elements/Superscript';
import * as Styled from './BannerTitle.style';

export interface IBannerTitleProps {
  script?: string;
  superScript?: string;
  title: string | string[];
  subTitle?: React.ReactNode | React.ReactElement;
  subTitle2?: string;
}

const BannerTitle: React.FC<IBannerTitleProps> = ({
  script,
  superScript,
  title,
  subTitle,
  subTitle2,
}) => (
  <Box data-testid="BannerTitle" sx={Styled.BannerTitle}>
    {script && (
      <Box sx={Styled.HotNReadyBlock}>
        <Script sx={Styled.TitleScript}>
          {script}
          {superScript && <Superscript>{superScript}</Superscript>}
        </Script>
      </Box>
    )}
    <ComponentListRenderer listText={title} />
    {subTitle && (
      <Box data-testid="SubTitleText" sx={Styled.SubTitleText}>
        {subTitle}
      </Box>
    )}
    {subTitle2 && (
      <Box data-testid="SubTitle2Text" sx={Styled.SubTitle2Text}>
        {subTitle2}
      </Box>
    )}
  </Box>
);

export default BannerTitle;
