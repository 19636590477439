import * as React from 'react';
import Flex from '@lce/slice_v2/Layout/Flex';
import Image from '@lce/slice_v2/Elements/Image';
import { IImageBannerProps } from '../../../../api/HomePage/useActiveBanner';

enum BannerWidth {
  Full = '100%',
  Unset = 'unset',
}

const ImageBanner: React.FC<IImageBannerProps> = ({  
  showBackgroundColor,
  backgroundColor,
  desktopImage,
  mobileImage,
}) => {
  const desktopBannerUrl = desktopImage.url;
  const mobileBannerUrl = mobileImage.url;

  const bannerWidth: BannerWidth = showBackgroundColor ? BannerWidth.Unset : BannerWidth.Full;
  const bgColor = backgroundColor.hex;

  return (
    <Flex data-testid="image-banner" sx={{ backgroundColor: bgColor, alignItems: 'center' }}>
      <Image alt={desktopImage.alt} src={desktopBannerUrl} sx={{ width: bannerWidth, display: ['none', 'flex'], mx: 'auto' }} />
      <Image alt={mobileImage.alt} src={mobileBannerUrl} sx={{ width: '100%', display: ['flex', 'none'], mx: 'auto' }} />
    </Flex>
  );
};

export default ImageBanner;
