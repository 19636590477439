import * as React from 'react';
import { map as _map } from 'lodash';
import Box from '@lce/slice_v2/Layout/Box';

interface IComponentListRendererProps {
  listText?: string | string[];
  Component?: any;
}

const ComponentListRenderer: React.FC<IComponentListRendererProps> = ({ listText, Component = Box }) => {
  if (Array.isArray(listText)) {
    return (
      <>
        {_map(listText, value => (
          <Component data-testid="ListComponent" key={value}>
            {value}
          </Component>
        ))}
      </>
    );
  }

  if (listText && !Array.isArray(listText)) {
    return <Component data-testid="SingleComponent">{listText}</Component>;
  }
  return null;
};

export default ComponentListRenderer;
