import { graphql, useStaticQuery } from 'gatsby';
import { map as _map } from 'lodash';

import localeFilter from '@lce/intl-util/src/localeFilter';

interface IDatoQualityBannerProps {
  qualityBanner: {
    nodes: IQualityBanner[];
  };
}

interface IQualityBanner {
  locale: string;
  qualityBannerSections: IQualityBannerItem[];
}

interface IQualityBannerItem {
  image: {
    url: string;
  };
  id: string;
  textDescription: string;
  title: string;
}

// eslint-disable-next-line max-lines-per-function
const useQualityBanner = (locale: string) => {
  const data: IDatoQualityBannerProps = useStaticQuery(graphql`
    query QualityBannerMsQuery {
      qualityBanner: allDatoCmsQualityBanner {
        nodes {
          locale
          qualityBannerSections {
            locale
            image {
              url
            }
            id
            textDescription
            title
          }
        }
      }
    }
  `);

  const filteredQualityBanner = localeFilter(data.qualityBanner.nodes, locale);

  const cleanedQualityBannerItems = _map(
    filteredQualityBanner && filteredQualityBanner.qualityBannerSections,
    section => ({
      ...section,
      image: section.image.url,
    }),
  );

  return {
    ...cleanedQualityBannerItems,
  };
};

export default useQualityBanner;
