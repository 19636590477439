import { graphql, useStaticQuery } from 'gatsby';
import { forEach as _forEach } from 'lodash';
import { IDatoImage } from '@lce/intl-types/src/IDatoImage';
import { ILineObject } from '@lce/intl-types/src/ILineObject';
import franchiseeFilter from '@lce/intl-util/src/FranchiseeFilter';
import { Direction } from '@lce/intl-types/src/IDirection';
import removeLine from '@lce/intl-util/src/removeLine';
import franchiseeDirection from '@lce/intl-util/src/FranchiseeDirection';
import { IImageBannerImage } from '@lce/intl-types/src/IImageBannerProps';

import useFranchisees from '../FranchiseeId/hooks/useFranchisees';
import { useSelectedFranchiseeId } from '../FranchiseeId/hooks/useSelectedFranchiseeId';

interface IDatoHomePageBannerProps {
  homePageBanner: {
    nodes: IDatoNodeHomePageBannerProps[];
  };
}

interface IDatoNodeHomePageBannerProps {
  franchisee: {
    name: string;
    originalId: string;
  };
  isFullWidth: boolean;
  activeBanners: IDatoActiveBannerProps[];
}

interface IDatoActiveBannerProps extends IDatoImageBannerProps, IDatoTextBannerProps {};

interface IDatoImageBannerProps {
  __typename: string;
  bannerTitle: string;
  startDate?: string;
  endDate?: string;
  showBackgroundColor: boolean;
  backgroundColor: {
    hex: string;
  };
  desktopImage: IImageBannerImage;
  mobileImage: IImageBannerImage;
}

interface IDatoTextBannerProps { 
  __typename: string;
  bannerTitle: string;
  startDate?: string;
  endDate?: string;
  image: IDatoImage;
  currencySymbol?: IDatoImage;
  script?: string;
  superScript?: string;
  cents?: string;
  subCents?: string;
  dollars?: string;
  title: ILineObject[];
  subTitle?: string;
  subTitle2?: string;
}

export interface IImageBannerProps {
  showBackgroundColor: boolean;
  backgroundColor: {
    hex: string;
  };
  desktopImage: IImageBannerImage;
  mobileImage: IImageBannerImage;
}

export interface ITextBannerProps {
  image: IDatoImage;
  currencySymbol?: IDatoImage;
  script?: string;
  superScript?: string;
  cents?: string;
  subCents?: string;
  dollars?: string;
  title?: string[];
  subTitle?: string;
  subTitle2?: string;
};

export interface IActiveBannerProps {
  locale: string;
  direction: Direction;
  bannerTitle: string;
  startDate?: string;
  endDate?: string;
  imageBanner?: IImageBannerProps;
  textBanner?: ITextBannerProps;
}

// eslint-disable-next-line max-lines-per-function
const useActiveBanner = (locale: string) => {
  const data: IDatoHomePageBannerProps = useStaticQuery(graphql`
    query HomePageBannerMsQuery {
      homePageBanner: allDatoCmsActiveHomePageBanner {
        nodes {
          locale
          franchisee {
            name
            originalId
          }
          isFullWidth
          activeBanners {
            __typename
            ... on DatoCmsImageBanner {
              bannerTitle
              startDate
              endDate
              showBackgroundColor
              backgroundColor {
                hex
              }
              desktopImage {
                alt
                url
                title
              }
              mobileImage {
                alt
                title
                url
              }
            }
            ... on DatoCmsTextBanner {
              bannerTitle
              startDate
              endDate
              cents
              dollars
              currencySymbol {
                alt
                url
              }
              image {
                alt
                url
              }
              script
              subCents
              subTitle
              subTitle2
              superScript
              title {
                line
              }
            }
          }
        }
      }
    }
  `);
  const { franchiseeId: SelectedFranchiseeId } = useSelectedFranchiseeId(locale);
  const { franchisees } = useFranchisees(locale);

  const direction: Direction = franchiseeDirection(franchisees, SelectedFranchiseeId);

  const filteredOffsetBannersByFranchisee = franchiseeFilter<IDatoNodeHomePageBannerProps>(data.homePageBanner.nodes, SelectedFranchiseeId, locale);

  const cleanedActiveBanners: IActiveBannerProps[] = [];
  _forEach(filteredOffsetBannersByFranchisee?.activeBanners, banner => {
    if (banner.__typename === 'DatoCmsImageBanner') {
      cleanedActiveBanners.push({
        locale,
        direction,
        bannerTitle: banner.bannerTitle,
        startDate: banner.startDate,
        endDate: banner.endDate,
        imageBanner: {
          showBackgroundColor: banner.showBackgroundColor,
          backgroundColor: banner.backgroundColor,
          desktopImage: banner.desktopImage,
          mobileImage: banner.mobileImage,
        },
      });
    }

    if (banner.__typename === 'DatoCmsTextBanner') {
      cleanedActiveBanners.push({
        locale,
        direction,
        bannerTitle: banner.bannerTitle,
        startDate: banner.startDate,
        endDate: banner.endDate,
        textBanner: {
          image: banner.image,
          currencySymbol: banner.currencySymbol,
          script: banner.script,
          superScript: banner.superScript,
          cents: banner.cents,
          subCents: banner.subCents,
          dollars: banner.dollars,
          title: removeLine(banner.title),
          subTitle: banner.subTitle,
          subTitle2: banner.subTitle2,
        },
      });
    }
  });

  return {
    isFullWidth: filteredOffsetBannersByFranchisee?.isFullWidth,
    bannerData: cleanedActiveBanners,
  };
};

export default useActiveBanner;
