import * as React from 'react';
import { map as _map } from 'lodash';
import Flex from '@lce/slice_v2/Layout/Flex';
import Heading from '@lce/slice_v2/Elements/Heading';
import Text from '@lce/slice_v2/Elements/Text';
import Image from '@lce/slice_v2/Elements/Image';

import * as Styled from './QualityPanel.style';

interface IQualityPanelProps {
  sections: IQualityPanelSectionProps[];
}

export interface IQualityPanelSectionProps {
  id: string;
  image: string;
  textDescription: string;
  title: string;
}

const QualityPanel: React.FC<IQualityPanelProps> = ({ sections }) => (
  <Flex data-testid="QualityPanel" sx={Styled.QualityPanel}>
    {_map(sections, section => (
      <Flex key={section.id} sx={Styled.Section}>
        <Image alt="" src={section.image} sx={Styled.SectionImage} />
        <Heading as="h2" sx={Styled.Title}>{section.title}</Heading>
        <Text sx={Styled.Description}>{section.textDescription}</Text>
      </Flex>
    ))}
  </Flex>
);

export default QualityPanel;
