import { IImageBannerImage } from '@lce/intl-types/src/IImageBannerProps';
import { graphql, useStaticQuery } from 'gatsby';

export interface ITwoPanelImageBannerProps {
  twoPanelImageBanner: INodeTwoPanelImageBannerProps;
}

interface INodeTwoPanelImageBannerProps {
  nodes: IDatoTwoPanelImageBannerProps[];
}

export interface IDatoTwoPanelImageBannerProps {
  leftDesktopImage: IImageBannerImage;
  leftMobileImage: IImageBannerImage;
  rightDesktopImage: IImageBannerImage;
  rightMobileImage: IImageBannerImage;
}

const useTwoPanelImageBanners = () => {
  const data: ITwoPanelImageBannerProps = useStaticQuery(graphql`
    query TwoPanelImageBannersMsQuery {
      twoPanelImageBanner: allDatoCmsTwoPanelImageBanner {
        nodes {
          leftDesktopImage {
            alt
            url
            title
          }
          leftMobileImage {
            alt
            url
            title
          }
          rightDesktopImage {
            alt
            url
            title
          }
          rightMobileImage {
            alt
            url
            title
          }
        }
      }
    }
  `);

  return {
    ...data.twoPanelImageBanner.nodes[0],
  };
};

export default useTwoPanelImageBanners;


