import * as React from 'react';
import Flex from '@lce/slice_v2/Layout/Flex';
import Box from '@lce/slice_v2/Layout/Box';
import Image from '@lce/slice_v2/Elements/Image';

import { IDatoImage } from '@lce/intl-types/src/IDatoImage';
import { Direction } from '@lce/intl-types/src/IDirection';
import checkCountryLocale from '@lce/intl-util/src/CheckCountryLocale';
import * as Styled from './LtoPrice.style';

export interface ILtoPriceProps {
  currencySymbol?: IDatoImage;
  dollars?: string;
  cents?: string;
  subCents?: string;
  direction: Direction;
}

// eslint-disable-next-line complexity
const LtoPrice: React.FC<ILtoPriceProps> = ({ dollars, direction, cents, subCents, currencySymbol }) => {
  const isRussiaSite = checkCountryLocale('ru-ru');
    
  return (
    <Flex
      sx={{
        ...Styled.LtoPrice,
        transform: ['translateX(-15px)', `translateX(${direction === 'rtl' ? '40px' : '-40px' })`],
      }}
    >
      {!isRussiaSite && currencySymbol && currencySymbol.url && currencySymbol.alt && (
        <Image alt={currencySymbol.alt} src={currencySymbol.url} sx={Styled.PriceSymbol} />
      )}

      <Flex sx={Styled.Price}>
        {dollars && <Box className="notranslate" sx={Styled.Dollars}>{dollars}</Box>}
        {cents && <Box className="notranslate" sx={Styled.Cents}>{cents}</Box>}
        {subCents && <Box sx={Styled.Ivi}>{subCents}</Box>}
      </Flex>
      {isRussiaSite && currencySymbol && currencySymbol.url && currencySymbol.alt && (
        <Image alt={currencySymbol.alt} src={currencySymbol.url} sx={Styled.PriceSymbolRussia} />
      )}
    </Flex>
  );
};

export default LtoPrice;
