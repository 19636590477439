import { SxStyleProp } from 'theme-ui';

export const BannerTitle: SxStyleProp = {
  color: 'white',
  position: 'relative',
  pt: '24px',
  lineHeight: '0.8em',
};

export const HotNReadyBlock: SxStyleProp = {
  position: 'absolute',
  top: ['-2px', '-10px'],
  fontSize: '0.375em',
  mb: '10px',
};

export const TitleScript: SxStyleProp = {
  color: 'black',
  fontWeight: 'normal',
  fontSize: '1.375em',
  mb: '0',
};

export const SubTitleText: SxStyleProp = {
  color: 'black',
  lineHeight: '1.15em',
  fontSize: '0.55em',
};

export const SubTitle2Text: SxStyleProp = {
  color: 'black',
  lineHeight: '1.15em',
  fontSize: '0.35em',
};
