import { SxStyleProp } from 'theme-ui';

export const ImageBannerContainer: SxStyleProp = {
  display: 'grid',
  gridTemplateColumns: ['1fr', '1fr', '66.5% 33.5%'],
  flexDirection: ['column', 'column', 'row'],
  justifyContent: ['center', 'center', 'space-between'],
  alignContent: 'center',
  mx: 'auto',
  gap: [4, 0],
};

export const DesktopImage: SxStyleProp = {
  width: '100%',
  height: '100%',
  maxWidth: ['none', 'fit-content', 'none'],
  display: ['none', 'flex'],
  mx: 'auto',
};

export const MobileLeftImage: SxStyleProp = {
  width: '100%',
  mb: 4,
  display: ['flex', 'none'],
  mx: 'auto',
};

export const MobileRightImage: SxStyleProp = {
  width: '100%',
  display: ['flex', 'none'],
  mx: 'auto',
};
