import { SxStyleProp } from 'theme-ui';

export const QualityPanel: SxStyleProp = {
  backgroundColor: 'primary',
  width: '100%',
  flexDirection: ['column', 'row'],
  justifyContent: 'space-evenly',
  p: '20px',
  minHeight: '1px',
};

export const Section: SxStyleProp = {
  width: ['100%', '33%'],
  justifyContent: 'flex-start',
  flexDirection: 'column',
  p: ['20px 0', '40px 0'],
  minHeight: '1px',
};

export const Title: SxStyleProp = {
  color: 'white',
  textTransform: 'uppercase',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: 16,
  m: 0,
};

export const Description: SxStyleProp = {
  textAlign: 'center',
  fontSize: 12,
  p: '0 8px',
  lineHeight: '1.2em',
};

export const SectionImage: SxStyleProp = {
  width: '100%',
  height: 'auto',
  minHeight: '1px',
};
