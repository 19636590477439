import * as React from 'react';
import { Box, Flex } from '@lce/slice_v2/Layout';
import { ITwoPanelImageBannerProps } from '@lce/intl-types/src/ITwoPanelImageBannerProps';

import TwoPanelImageBanner from '../Panels/TwoPanelImageBanner';
import QualityPanel, { IQualityPanelSectionProps } from '../Panels/QualityPanel';
import HotNReadyPanel, { IHotNReadyPanelProps } from '../Panels/HotNReadyPanel';

export enum PanelLayout {
  TWO_PANEL = 'two_panel',
  TWO_PANEL_IMAGEBANNER = 'two_panel_image_banner',
}

export interface IPanelLoaderProps {
  panelLayout: PanelLayout;
  hotNReadyPanel: IHotNReadyPanelProps;
  qualityPanel: IQualityPanelSectionProps[];
  twoPanelImageBannerData?: ITwoPanelImageBannerProps;
}

const PanelLoader: React.FC<IPanelLoaderProps> = ({ panelLayout, hotNReadyPanel, qualityPanel, twoPanelImageBannerData }) => (
  <Flex sx={{ maxWidth: '1280px' }}>
    { panelLayout === PanelLayout.TWO_PANEL_IMAGEBANNER && twoPanelImageBannerData ?
      <TwoPanelImageBanner data={twoPanelImageBannerData} /> :
      <Box
        data-testid="TwoPanelLayout"
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: ['1fr','1fr','2fr 1fr'],
          gap: 4,
          mb: 4,
        }}
      >
        <QualityPanel sections={qualityPanel} />
        <HotNReadyPanel {...hotNReadyPanel} />
      </Box>
    }
  </Flex>
);

export default PanelLoader;
