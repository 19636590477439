import { forEach as _forEach } from 'lodash';
import * as moment from 'moment';

import { IActiveBannerProps } from '../../../api/HomePage/useActiveBanner';

const dateTimeCheck = (banner: IActiveBannerProps) => {
  if (!banner.endDate && !banner.startDate) {
    return true;
  }
  if (banner.endDate && banner.startDate && moment().isAfter(banner.startDate) && moment().isBefore(banner.endDate)) {
    return true;
  }
  if (banner.endDate && !banner.startDate && moment().isBefore(banner.endDate)) {
    return true;
  }
  if (!banner.endDate && banner.startDate && moment().isAfter(banner.startDate)) {
    return true;
  }
  return false;
};

export const sanitizedList = (bannerData: IActiveBannerProps[]) => {
  const bannerList: IActiveBannerProps[] = [];
  _forEach(bannerData, (banner: IActiveBannerProps) => {
    if (banner.imageBanner || banner.textBanner) {
      if (dateTimeCheck(banner)) {
        bannerList.push(banner);
      }
    }
  });
  return bannerList;
};
