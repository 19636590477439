import * as React from 'react';
import { Box, Flex } from '@lce/slice_v2/Layout';
import Image from '@lce/slice_v2/Elements/Image';
import { ITwoPanelImageBannerProps } from '@lce/intl-types/src/ITwoPanelImageBannerProps';

import * as Styled from './TwoPanelImageBanner.style';

export interface ITwoPanelImageBanner {
  data: ITwoPanelImageBannerProps;
}

const TwoPanelImageBanner: React.FC<ITwoPanelImageBanner> = ({ data }) => {
  const { leftDesktopImage, leftMobileImage, rightDesktopImage, rightMobileImage } = data;
  return (
    <Box data-testid="TwoPanelImageBanner" sx={{ width: '100%', mb: 4 }}>
      <Flex data-testid="banner-container" sx={{ ...Styled.ImageBannerContainer }}>
        <Box data-testid="left-banner" sx={{ mr: [0, 0, 4] }}>
          <Image alt={leftDesktopImage.alt} src={leftDesktopImage.url} sx={ Styled.DesktopImage } />
          <Image alt={leftMobileImage.alt} src={leftMobileImage.url} sx={ Styled.MobileLeftImage } />
        </Box>
        <Box data-testid="right-banner">
          <Image alt={rightDesktopImage.alt} src={rightDesktopImage.url} sx={ Styled.DesktopImage } />
          <Image alt={rightMobileImage.alt} src={rightMobileImage.url} sx={ Styled.MobileRightImage } />
        </Box>
      </Flex>
    </Box>
  );
};

export default TwoPanelImageBanner;
