import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';
import { SxStyleProp } from 'theme-ui';

export interface ISuperscriptProps {
  sx?: SxStyleProp;
}

const Superscript: React.FC<ISuperscriptProps> = ({ sx, children }) => (
  <Box
    as='span'
    data-testid="Superscript"
    sx={{
      position: 'absolute',
      fontSize: '33%',
      p: '1px',
      ...sx,
    }}
  >
    {children}
  </Box>
);

export default Superscript;
