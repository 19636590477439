import * as React from 'react';
import Flex from '@lce/slice_v2/Layout/Flex';
import Box from '@lce/slice_v2/Layout/Box';
import Image from '@lce/slice_v2/Elements/Image';
import { Direction } from '@lce/intl-types/src/IDirection';
import LtoPrice from '@lce/intl-ui/src/Patterns/Banners/DefaultBanner/components/LtoPrice';
import BannerTitle from '@lce/intl-ui/src/Patterns/Banners/DefaultBanner/components/BannerTitle';

import { ITextBannerProps } from '../../../../api/HomePage/useActiveBanner';
import * as Styled from './TextBanner.style';

const TextBanner: React.FC<ITextBannerProps & { direction: Direction; locale: string}> = ({
  image,
  currencySymbol,
  script,
  superScript,
  cents,
  subCents,
  dollars,
  title,
  subTitle,
  subTitle2,
  direction,
  locale,
}) => (
  <Flex data-testid="text-banner" sx={Styled.Lto}>
    <Box sx={Styled.LtoImageBlock}>
      {image && image.url &&
        <Image alt="" src={image.url} sx={Styled.LtoImage} />
      }
    </Box>
    <Flex data-testId="LtoMessageBlock" sx={locale === 'ru-RU' ? { ...Styled.LtoMessageBlock, fontSize: [26, 32] } : Styled.LtoMessageBlock}>
      <Box data-testId="LtoText" sx={locale === 'ru-RU' ? { ...Styled.LtoText, fontSize: [20, 26] } :  Styled.LtoText}>
        <LtoPrice cents={cents} currencySymbol={currencySymbol} direction={direction} dollars={dollars} subCents={subCents} />
        {title && <BannerTitle script={script} subTitle={subTitle} subTitle2={subTitle2} superScript={superScript} title={title} />}
      </Box>
    </Flex>
  </Flex>
);

export default TextBanner;
