import * as React from 'react';

import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';
import Container from '@lce/intl-ui/src/Layout/Container';

import PanelLoader from '../ui/PageComponents/Home/PanelLoader';
import BannerLoader from '../ui/Modules/BannerLoader';
import Layout from '../ui/ConnectedLayout';
import useActiveBanner from '../api/HomePage/useActiveBanner';
import useSiteConfig from '../api/Config/useSiteConfig';
import useHotNReadyPanel from '../api/HomePage/useHotNReadyPanel';
import useTwoPanelImageBanners from '../api/HomePage/useTwoPanelImageBanners';
import useQualityPanel from '../api/HomePage/useQualityPanel';

const Home: React.FC<IDefaultPageProps> = props => {
  const { locale } = props.pathContext;

  const bannerProps = useActiveBanner(locale);
  const hotNReadyPanel = useHotNReadyPanel(locale);
  const qualityPanel = useQualityPanel(locale);
  const twoPanelImageBannerData = useTwoPanelImageBanners();
  const { siteConfig: { panelLayout } } = useSiteConfig(locale);
  const panelProps = { panelLayout, hotNReadyPanel, qualityPanel, twoPanelImageBannerData };

  return (
    <Layout {...props} >
      <Container data-testid="home-page" sx={{ maxWidth: '100%', display:'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
        <BannerLoader {...bannerProps} />
        <PanelLoader {...panelProps} />
      </Container>
    </Layout>
  );
};

export default Home;
