import * as React from 'react';

import Box from '@lce/slice_v2/Layout/Box';
import { SxStyleProp } from 'theme-ui';
import checkCountryLocale from '@lce/intl-util/src/CheckCountryLocale';

export interface IScriptProps {
  tilt?: number;
  sx?: SxStyleProp;
}

const Script: React.FC<IScriptProps> = ({ tilt = 3, sx, children }) => {
  const isRussiaSite = checkCountryLocale('ru-ru');
  return(
    <Box
      data-testid="Script"
      sx={{
        fontFamily: 'script',
        transform: `rotate(-${isRussiaSite ? 0 : tilt}deg)`,
        textTransform: 'none',
        display: 'inline-block',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default Script;
