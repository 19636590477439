import { SxStyleProp } from 'theme-ui';

export const LtoPrice: SxStyleProp = {
  color: 'white',
  textAlign: 'center',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  fontSize: [58, 80],
};

export const Price: SxStyleProp = {
  color: 'inherit',
  lineHeight: '0.8em',
  whiteSpace: 'nowrap',
  position: 'relative',
  flexWrap: 'nowrap',
  flexDirection: 'row',
};

export const PriceSymbol: SxStyleProp = {
  alignSelf: 'flex-start',
  width: ['16px', '32px'],
  height: ['48px', '72px'],
  mt: ['8px', '16px'],
};

export const PriceSymbolRussia: SxStyleProp = {
  alignSelf: 'flex-start',
  width: ['40px', '56px'],
  height: ['80px', '104px'],
};

export const Dollars: SxStyleProp = {
  fontSize: 'inherit',
  pb: '20px',
};

export const Cents: SxStyleProp = {
  fontSize: '0.55em',
};

export const Ivi: SxStyleProp = {
  fontWeight: 'normal',
  position: 'absolute',
  right: '4px',
  bottom: '8px',
  fontSize: '0.125em',
  lineHeight: '1em',
};
